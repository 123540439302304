<template>
    <div class="px-5 py-5">
      <v-col md="12"></v-col>
      <div>
        <template>
          <v-row>
            <v-col md="3">
              <v-text-field   :label="$t('hr.time.from-date')" type="date" 
                                v-model="filters.from">
              </v-text-field>
            </v-col>
            <v-col md="3">
              <v-text-field   :label="$t('hr.time.to-date')" type="date" 
                                v-model="filters.to">
              </v-text-field>
            </v-col>
            <v-col md="3">
                <v-autocomplete :items="users" item-text="name" item-value="id" v-model="filters.sender_id"
                                      @keypress="fetchUsers" :loading="usersLoading"
                                      :label="$t('Sender')"   ></v-autocomplete>
            </v-col> 
            <v-col md="3">
              <v-select
                v-model="filters.type"  
                color="#757575" 
                :label="$t('type')"
                :items="typeListFilter"
                item-text="name"
                item-value="value"
              ></v-select>
            </v-col> 
          </v-row>
        <v-row>
          <v-col md="2">
            <v-select
                v-model="filters.read"  
                color="#757575" 
                :items="readListFilter"
                item-text="name"
                item-value="value"
            ></v-select>
          </v-col>
          <v-col md="3">
            <v-text-field v-model="filters.title" :label="$t('Message Title')">
            </v-text-field>
          </v-col> 
          <v-col md="4">
            <v-text-field v-model="filters.message" :label="$t('Message')">
            </v-text-field>
          </v-col> 


          <v-col md="1" class="py-7">
              <v-icon large color="gray" @click="clearFilter">mdi-close</v-icon>
          </v-col>
          <v-col md="1" class="py-7">
              <v-icon large color="gray" @click="getIncomingMail"> mdi-magnify </v-icon>
          </v-col>
        </v-row>

        </template>
      </div>
      <v-data-table :dark="$store.state.isDarkMode"
      :headers="headers"
      :loading="loading"
      :items="items"
      
      >

      <template v-slot:item="{ item }">
    <tr :class="{ 'bold-row': shouldMakeRowBold(item) }">
      <td>{{ item.date }}</td>
      <td>{{ item.sender }}</td>
      <td>{{ item.title }}</td>
      <td>{{ item.type }}</td>
      <td><v-btn  @click="showMessage(item)" color="green" icon>
          <v-icon class="ml-1">mdi-eye</v-icon></v-btn
        ></td>
    </tr>
  </template>
      
      </v-data-table>
    </div>
  </template>
  
  <script>
  import axios from "axios";
  import i18n from '@/i18n';
  import debounce from "../../../../helpers/debounce";
  
  export default {
    components: {
     
    },
    computed: {
      shouldMakeRowBold() {
      return (item) => {
        if(item.read == false){
          return true;
        }
        return false;
      };
    }
    
  },
    data() {
      return {
        typeListFilter: [
        {
          name: i18n.t("Mail"),
          value: "mail",
        },
        {
          name: i18n.t("Task"),
          value: "task",
        },
        {
          name: i18n.t("Broadcast"),
          value: "broadcast",
        },
        {
          name: i18n.t("Reply"),
          value: "reply",
        },
      ],
      readListFilter: [
        {
          name: i18n.t("All Messages"),
          value: null,
        },
        {
          name: i18n.t("Read"),
          value: "true",
        },
        {
          name: i18n.t("Un Read"),
          value: "false",
        },
      ],
        headers: [
          { text: i18n.t("date"), value: "date" },
          { text: i18n.t("Sender"), value: "sender" },
          { text: i18n.t("Message Title"), value: "title" },
          { text: i18n.t("type"), value: "type" },
          { text: "", value: "actions" },
        ],
        items: [],
        usersLoading:null,
        users: [],
        loading: false,
        filters: {
          sender_id: null,
          title: null,
          message: null,
          type: null,
          from: null,
          to: null,
          read: null,
      },
      };
    },
    methods: {
      showMessage(item){
        if(item.type=='reply'){
          this.$router.push(
                `showMessage/${item.parent_id}`
              );
        } else {
          this.$router.push(
                `showMessage/${item.id}`
              );
        }
      },
      async fetchUsers(searchQuery) {
      debounce(async () => {
        let searchValue = searchQuery.target.value;
        this.usersLoading = true;
        if (!searchValue) return;
        try {
          const searchResult = await axios.get("/user/auto-complete", {
            params: {
              name: searchValue,
            },
          });
          this.users = searchResult.data.users;
        } catch (err) {
          console.log("err", err);
        } finally {
          this.usersLoading = false;
        }
      }, 500)();
    },
      clearFilter() {
        this.filters.message = undefined;
        this.filters.sender_id = undefined;
        this.filters.title = undefined;
        this.filters.from = undefined;
        this.filters.to = undefined;
        this.filters.type = undefined;
        this.filters.read = null;
       
       
        // this.page = 1;
        this.getIncomingMail();
      },
      async getIncomingMail() {
        const filterTemp = {};
          Object.keys(this.filters).forEach((key) => {
            if (this.filters[key]) filterTemp[key] = this.filters[key];
          });
        this.loading = true;
        const res = await axios.get("/message/inbox",{
            params: {
             
              ...filterTemp,
            },
          });
        
        const arr= Array.from(res.data.data)
          if(Array.isArray(arr))
          {
            console.log(arr);
            this.items = arr.map((el) => {
              
              return {
                ...el,
                
              };
            });
          }
          console.log(this.items);
        
        this.loading = false;
      },
    },
    created() {
      this.getIncomingMail();
    },
  };
  </script>
  
  <style>
  .bold-row {
    font-weight: bold;
  }
  </style>
  